import { useContext } from 'react';
import { ResourcesContext } from './ResourcesProvider';

const useClearResource = (resourceKey: string = '') => {
  const { clearResource } = useContext(ResourcesContext);

  return clearResource && clearResource.bind(this, resourceKey);
};

export { useClearResource };
