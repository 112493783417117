import React, {
  FC, useState, createContext, useCallback,
} from 'react';
import { set } from 'lodash/fp';
import axios, { Method } from 'axios';
import { createResourceData } from './utils';

export type ResourceConfig = {
  apiEndpoint: string,
  method: Method,
  // eslint-disable-next-line no-unused-vars
  transformDTO?: (dto: any) => any,
  response?: ApiResponse,
  headers?: { [k: string]: string},
}

export type ApiResponse = {
  isLoading: boolean,
  error: any,
  data: any,
}

export type Props = {
  children: any,
  config: ResourcesMap,
  apiPath?: string,
}

export type ResourcesMap = {
  [k: string]: ResourceConfig
}

export type RequestPayload = {
  apiKey: string,
  reqData?: {[k: string]: any },
  reqBody?: {[k: string]: any },
  isLoadMore?: boolean,
  targetSystem?: string,
}

export type ResourceCaller = {
  // eslint-disable-next-line no-unused-vars
  (payload: RequestPayload): void
}

export type ResourceRemover = {
  // eslint-disable-next-line no-unused-vars
  (payload: string): void
}

export type RContext = {
  resources?: ResourcesMap,
  makeResourceCall?: ResourceCaller,
  clearResource?: ResourceRemover,
}

const ResourcesContext = createContext<RContext>({});

const ResourcesProvider: FC<Props> = ({ children, config, apiPath = 'http://localhost:9000' }) => {
  const [resources, setResources] = useState<ResourcesMap>(config);
  const clearResource: ResourceRemover = useCallback(apiKey => {
    setResources(prevState => (set([apiKey, 'response'], { isLoading: false, data: null, error: null }, prevState)));
  }, []);

  const makeResourceCall: ResourceCaller = useCallback(async (reqPayload: RequestPayload) => {
    const {
      apiKey, reqData, reqBody, isLoadMore,
    } = reqPayload;

    // set the resource as loading
    setResources(prevState => (set([apiKey, 'response', 'isLoading'], true, prevState)));

    const { [apiKey]: { method } } = resources;

    // this config is passed to axios call
    // we are sending all additional data as query param because spring mvc will transform body to query param anyway
    const requestConfig = {
      ...(reqData ? { params: reqData } : {}),
      ...(method === 'POST' ? { data: reqBody } : {}),
      method,
    };

    try {
      // TODO if api pathname becomes configurable change it here
      const url = `${apiPath}/${apiKey}`;

      const res = await axios(url, requestConfig);
      const { data } = res;

      // setting successful response to context

      return setResources(prevState => (
        set(
          [apiKey, 'response'],
          {
            isLoading: false,
            data: createResourceData({
              oldData: prevState[apiKey].response?.data,
              response: data,
              isLoadMore,
            }),
            error: null,
          },
          prevState
        )));
    } catch (e) {
      // call failed before it was made, generic error is set on resource
      if (!e.response) {
        return setResources(prevState => (set(
          [apiKey, 'response'],
          { error: { message: 'GENERIC_NETWORK_ERROR' }, isLoading: false, data: null },
          prevState)));
      }

      // bad call was made, error returned from the api is set on resource
      return setResources(prevState => (set([apiKey, 'response'], { error: e.response.data, isLoading: false, data: null }, prevState)));
    }
  }, []);

  const contextValue: RContext = { resources, makeResourceCall, clearResource };

  return (
    <ResourcesContext.Provider value={contextValue}>
      {children}
    </ResourcesContext.Provider>
  );
};

export { ResourcesProvider, ResourcesContext };
