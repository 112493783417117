import { useContext, useMemo } from 'react';
import { get } from 'lodash';
import { ApiResponse, ResourcesContext, ResourceCaller } from './ResourcesProvider';

type UseRaffaella = [ApiResponse, ResourceCaller?];

const useRaffaella = (resourceKey: string = '') => {
  const { makeResourceCall, resources } = useContext(ResourcesContext);
  const defaultResponse: ApiResponse = { isLoading: false, data: null, error: null };

  const response = get(resources, [resourceKey, 'response'], defaultResponse);

  // eslint-disable-next-line no-unused-vars
  const raffaellaResult: UseRaffaella = [response, makeResourceCall];

  return useMemo(() => (raffaellaResult), [response, makeResourceCall]);
};

export { useRaffaella, UseRaffaella };
