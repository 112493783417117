type ResourcePayload = {
  response: any,
  oldData: any,
  isLoadMore?: boolean
}
/**
 * createResourceData - returns object to be set as resource in context. It determines if resource should replace old
 * resource or if it should be appended to the old resource. The latter case should be used for load-more paginated resources.
 * @param payload
 */
const createResourceData = (payload: ResourcePayload) => {
  const { response, oldData, isLoadMore } = payload;
  // there is nothing in context for the current resource
  // so just return resource regardless if it is paginated or not
  // (in paginated case this is the first call)

  if (typeof oldData === 'undefined') {
    return response;
  }

  // if it is load-more resource append it to the previous resources
  if (isLoadMore) {
    if (!Array.isArray(oldData) && !Array.isArray(response)) {
      throw Error('Resource is fetched as loadMore, but it is not normalized into array. Update your transformDTO function for this resource to transform response into array');
    }

    return [
      ...oldData,
      ...response,
    ];
  }

  return response;
};

export { createResourceData };
